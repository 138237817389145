"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./Carousel.module.scss";
import For from "@/components/common/For";
import H2 from "@/components/common/H2";
import AnimateIn from "@/components/common/AnimateIn";
import Image from "@/components/common/Image";
import RightArrow from "@/components/common/RightArrow";
import clsx from "clsx";
import { ArrowLeft, ArrowRight, BookText, GalleryHorizontal, Grid, Minus, Plus, X } from "lucide-react";
import If from "@/components/common/If";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { BackgroundColors, BackgroundColorText, Padding } from "@/config/site";
import { BlockProps } from "@/lib/types";
import { Heading } from "@/components/ui/Heading";
import { Button } from "@/components/actions/Button";
import { CTAs } from "@/components/ui/CTAs";
import Parallax from "@/components/common/Parallax";
import useResizeObserver from "@/hooks/useResizeObserver";
import { Select } from "@/components/form/Select";
import { SwatchesProvider, useSwatches } from "@/providers/Swatches";
interface CarouselProps {
  block: BlockProps;
  type?: string;
  canBeGrid?: boolean;
}

// { value: "SRO", label: "Plain Sliced Red Oak" },
// { value: "SWO", label: "Plain Sliced White Oak" },
// { value: "SWM", label: "Plain Sliced White Maple" },
// { value: "RWB", label: "Rotary White Birch" },
// { value: "SWB", label: "Plain Sliced White Birch" },
// { value: "RNB", label: "Rotary Natural Birch" },
// { value: "PSC", label: "Plain Sliced Cherry" },
// { value: "FCM", label: "Flat Cut African Mahogany" },
// { value: "PSW", label: "Plain Sliced Walnut" },

const alts = {
  SRO: "Plain Sliced Red Oak",
  SWO: "Plain Sliced White Oak",
  SWM: "Plain Sliced White Maple",
  RWB: "Rotary White Birch",
  SWB: "Plain Sliced White Birch",
  RNB: "Rotary Natural Birch",
  FCM: "Flat Cut African Mahogany",
  PSC: "Plain Sliced Cherry",
  PSW: "Plain Sliced Walnut"
};
const getPrefix = tags => {
  let prefix = "";
  const prefixes = {
    "Case Study": "/case-studies",
    Solution: "/solutions",
    Category: "/categories",
    Series: "/series",
    Marketing: "/markets"
  };
  if (tags.length > 0) {
    tags.some(tag => {
      const hasPrefix = prefixes[tag.name];
      if (hasPrefix) {
        prefix = hasPrefix;
        return true;
      }
    });
  }
  return prefix;
};
const Swatch = ({
  thumbnail,
  index,
  currentIndex,
  type = "hpdl",
  image = "",
  openStory = () => {},
  ...props
}) => {
  const isHPDL = type === "hpdl";
  const imageUrl = type !== "hpdl" ? thumbnail?.image : thumbnail?.image?.[0]?.cdn_url;
  const {
    setSelectedSwatch
  } = useSwatches();
  return <button className={`stack ${isHPDL ? "flex-swatch" : "flex-card"} ml-0 relative text-left p-0`} onClick={() => setSelectedSwatch(thumbnail)} data-sentry-component="Swatch" data-sentry-source-file="Carousel.tsx">
      <Image src={imageUrl} alt={`Thumbnail ${index}`} className={`transition-all w-full duration-500 ease-in-out aspect-[1/1] md:aspect-[495/666]`} imgClassName="aspect-[1/1] md:aspect-[495/666] w-full" maxWidth="1000" data-sentry-element="Image" data-sentry-source-file="Carousel.tsx" />
      <div className={`absolute swatch-title pl-2 pr-10 pt-2 top-0 left-0 z-[1] text-[1.25rem] stack`}>
        <span className="leading-none text-base text-black">
          {thumbnail.title}
        </span>
        <If condition={thumbnail?.subtitle || thumbnail?.type && !thumbnail?.legacy} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
          <span className="text-sm text-gold">
            {thumbnail?.subtitle || thumbnail?.type}
          </span>
        </If>
        <If condition={thumbnail?.legacy} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
          <span className="text-xs text-gold">Legacy</span>
        </If>
      </div>
      <If condition={!isHPDL} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        {/* <button
          className="absolute right-2 top-2"
          onClick={(ev) => {
            ev.stopPropagation();
            openStory(thumbnail);
          }}
         >
          <BookText className="text-gold" />
         </button> */}
      </If>
    </button>;
};
const NoScroll = ({
  block,
  className = "",
  contentRef = null,
  setGrid = () => {},
  grid = true,
  type = "hpdl",
  openStory = () => {},
  ...props
}) => {
  const {
    backgroundColor,
    showTitle,
    title
  } = block;
  const ctas = block?.ctAsCollection?.items || [];
  const thumbnails = block?.carouselCollection?.items || [];
  const padding = Padding[block.padding || "None"];
  const backgroundColorText = BackgroundColorText[backgroundColor];
  const bgColor = BackgroundColors[backgroundColor];
  const [results, setResults] = useState(thumbnails);
  const [filters, setFilters] = useState({
    species: "All",
    colorType: "All",
    legacy: "legacy"
  });
  const {
    setSwatchFilters,
    setAllSwatches
  } = useSwatches();
  const filterResults = data => {
    const {
      species,
      colorType,
      legacy
    } = filters;
    if (type !== "stains") return data;
    if (species === "All" && colorType === "All" && legacy === "all") return data;
    const hasLegacy = item => {
      if (legacy === "all") return true;
      if (item.legacy && legacy === "legacy") return true;
      if (!item.legacy && legacy === "new") return true;
      return false;
    };
    return data.filter(item => {
      const hasSpecies = species === "All" || item.species.includes(species);
      const hasColorType = colorType === "All" || item.type === colorType;
      return hasSpecies && hasColorType && hasLegacy(item);
    });
  };
  useEffect(() => {
    if (thumbnails) {
      const res = filterResults(thumbnails);
      setResults(res);
    }
  }, [filters]);
  useEffect(() => {
    if (thumbnails) {
      const res = filterResults(thumbnails);
      setResults(res);
    }
  }, [thumbnails]);
  useEffect(() => {
    setSwatchFilters?.(filters);
  }, [filters]);
  useEffect(() => {
    setAllSwatches?.(results);
  }, [results]);
  return <section className={`${styles.carousel} ${padding} ${bgColor} ${backgroundColorText} relative stack gap-12 px-gutter`} {...props} id={title && title?.toLowerCase()?.replace(" ", "-")} data-sentry-component="NoScroll" data-sentry-source-file="Carousel.tsx">
      <If condition={showTitle && title} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        <div className="sticky top-[79px] z-[15000] bg-white">
          <div className="container mx-auto  flex flex-col md:flex-row justify-between gap-4 py-12 border-b-2 border-[#96A2A1]">
            <div className="stack gap-2">
              <Heading as="h2" bottomBorder={false} isContainer={false} data-sentry-element="Heading" data-sentry-source-file="Carousel.tsx">
                {title}
              </Heading>
            </div>
            <div className="flex flex-col md:flex-row md:items-center gap-4">
              <If condition={type === "stains"} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
                <Select name="legacy" options={[{
                value: "all",
                label: "All"
              }, {
                value: "legacy",
                label: "Legacy"
              }, {
                value: "new",
                label: "New"
              }]} placeholder="Color Collection" value={filters.legacy || "legacy"} onChange={value => {
                setFilters({
                  ...filters,
                  legacy: value
                });
              }} data-sentry-element="Select" data-sentry-source-file="Carousel.tsx" />
                <Select name="species" options={[{
                value: "All",
                label: "All"
              }, {
                value: "SRO",
                label: "Plain Sliced Red Oak"
              }, {
                value: "SWO",
                label: "Plain Sliced White Oak"
              }, {
                value: "SWM",
                label: "Plain Sliced White Maple"
              }, {
                value: "RWB",
                label: "Rotary White Birch"
              }, {
                value: "SWB",
                label: "Plain Sliced White Birch"
              }, {
                value: "RNB",
                label: "Rotary Natural Birch"
              }, {
                value: "PSC",
                label: "Plain Sliced Cherry"
              }, {
                value: "FCM",
                label: "Flat Cut African Mahogany"
              }, {
                value: "PSW",
                label: "Plain Sliced Walnut"
              }
              // { value: "RRO", label: "Rift Red Oak" },
              // { value: "RWO", label: "Rift White Oak" },
              ]} placeholder="Species" value={filters.species || "SRO"} onChange={value => {
                setFilters({
                  ...filters,
                  species: value
                });
              }} data-sentry-element="Select" data-sentry-source-file="Carousel.tsx" />
                <Select name="type" options={[{
                value: "All",
                label: "All"
              }, {
                value: "Standard",
                label: "Standard"
              }, {
                value: "Standard+",
                label: "Standard+"
              }]} placeholder="Type" value={filters.colorType || "All"} onChange={value => {
                setFilters({
                  ...filters,
                  colorType: value
                });
              }} data-sentry-element="Select" data-sentry-source-file="Carousel.tsx" />
              </If>
              <div className="flex items-center gap-2">
                <button className="flex items-center gap-2" onClick={() => {
                setGrid(true);
              }}>
                  <Grid color={grid ? "black" : "gray"} data-sentry-element="Grid" data-sentry-source-file="Carousel.tsx" />
                </button>
                <button className="flex items-center gap-2" onClick={() => {
                setGrid(false);
              }}>
                  <GalleryHorizontal color={!grid ? "black" : "gray"} data-sentry-element="GalleryHorizontal" data-sentry-source-file="Carousel.tsx" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </If>
      <div className="stack gap-4">
        <If condition={type === "stains"} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
          <div className="container mx-auto">
            <p className="mb-8  lg:w-[50%]">
              Discover our classic shades in the original color collection,
              which includes all current stain options. We're introducing a new
              stain offering—preview the upcoming Standard and Standard+ colors
              by using the filter. Stay tuned for the official launch
              announcement!
            </p>
          </div>
        </If>
        <div className="w-full overflow-visible relative">
          <div className={clsx("swatch-grid mx-auto max-w-[100dvw] gap-4")} ref={contentRef}>
            <For each={results} render={(thumbnail, index) => {
            // console.log(filters.species, thumbnail);

            const getThumbnail = () => {
              if (type === "hpdl") return thumbnail?.image;
              if (type === "paints") return thumbnail?.images?.[0]?.cdn_url;
              if (filters.species === "All" && type === "stains") {
                return thumbnail?.swo?.[0]?.cdn_url;
              }
              const image = thumbnail?.[filters.species.toLowerCase()]?.[0]?.cdn_url;
              if (!image) {
                thumbnail?.images?.find(img => {
                  return img?.filename?.includes(filters.species) || img?.filename?.replace(/-/g, " ")?.includes(alts[filters.species]);
                })?.cdn_url;
              }
              return image;
            };
            const image = getThumbnail();

            // let image =
            //   filters.species !== "All" && type === "stains"
            //     ? thumbnail?.images?.find((img) => {
            //         return (
            //           img?.filename?.includes(filters.species) ||
            //           img?.filename
            //             ?.replace(/-/g, " ")
            //             ?.includes(alts[filters.species])
            //         );
            //       })?.cdn_url
            //     : thumbnail?.images?.[0]?.cdn_url;
            // if (type === "hpdl") {
            //   image = thumbnail?.image;
            // }

            return <Swatch key={thumbnail?.sys?.id} thumbnail={{
              ...thumbnail,
              image
            }} index={index} currentIndex={0} type={type} image={image} openStory={openStory} {...props} />;
          }} data-sentry-element="For" data-sentry-source-file="Carousel.tsx" />
          </div>
        </div>
      </div>

      <CTAs ctas={ctas} data-sentry-element="CTAs" data-sentry-source-file="Carousel.tsx" />
    </section>;
};
const Scrollable = ({
  block,
  className = "",
  contentRef = null,
  setGrid = () => {},
  grid = true,
  type = "hpdl",
  openStory = () => {},
  ...props
}) => {
  const {
    backgroundColor,
    showTitle,
    title
  } = block;
  const ctas = block?.ctAsCollection?.items || [];
  const thumbnails = block?.imageCarousel || block?.carouselCollection?.items || [];
  const padding = Padding[block.padding || "None"];
  const backgroundColorText = BackgroundColorText[backgroundColor];
  const bgColor = BackgroundColors[backgroundColor];
  const [results, setResults] = useState(thumbnails);
  const [filters, setFilters] = useState({
    species: "All",
    colorType: "All"
  });
  const filterResults = data => {
    const {
      species,
      colorType
    } = filters;
    if (type !== "stains") return data;
    if (species === "All" && colorType === "All") return data;
    return data.filter(item => {
      const hasSpecies = species === "All" || item.species.includes(species);
      const hasType = colorType === "All" || item.type === colorType;
      return hasSpecies && hasType;
    });
  };
  useEffect(() => {
    if (thumbnails) {
      const res = filterResults(thumbnails);
      setResults(res);
    }
  }, [thumbnails]);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: block.carouselType === "Case Studies",
    align: block.carouselType === "Case Studies" ? "center" : "start",
    startIndex: 0,
    //block.carouselType === "Case Studies" ? 1 : 0,
    containScroll: false,
    slidesToScroll: "auto"
  });
  const onInit = useCallback(embla => {
    setScrollSnaps(embla.scrollSnapList());
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openProject, setOpenProject] = useState(null);
  const prevSlide = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);
  const nextSlide = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);
  useEffect(() => {
    if (emblaApi) {
      // console.log(title, emblaApi);
      // Initialize Embla Carousel
      emblaApi.on("select", () => {
        // console.log("select");
        setCurrentIndex(emblaApi.selectedScrollSnap());
        // onChange(emblaApi.selectedScrollSnap());
      });
      emblaApi.on("settle", () => {
        // setSelected(embla.selectedScrollSnap());
      });
      emblaApi.on("resize", () => {});
      onInit(emblaApi);
    }
  }, [emblaApi]);
  useEffect(() => {
    if (thumbnails) {
      const res = filterResults(thumbnails);
      setResults(res);
    }
  }, [filters]);
  useEffect(() => {
    if (emblaApi) {
      setTimeout(() => {
        emblaApi?.reInit();
        emblaApi?.scrollTo(0);
        setScrollSnaps(emblaApi.scrollSnapList());
      }, 500);
    }
  }, [results]);

  // useEffect(() => {
  //   if (emblaApi) {
  //     setCurrentIndex(0);
  //     setTimeout(() => {
  //       emblaApi.scrollTo(0);
  //     }, 1000);
  //   }
  // }, [emblaApi, block]);

  return <section className={`${styles.carousel} ${padding} ${bgColor} ${backgroundColorText} relative stack gap-16`} {...props} id={title && title?.toLowerCase()?.replace(" ", "-")} data-sentry-component="Scrollable" data-sentry-source-file="Carousel.tsx">
      <If condition={showTitle && title} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
        <div className="sticky top-[79px] z-[15000] bg-white overflow-hidden">
          <div className="container mx-auto  flex flex-col md:flex-row justify-between gap-4 pb-12 border-b-2 border-[#96A2A1]">
            <Heading as="h2" bottomBorder={false} isContainer={false} data-sentry-element="Heading" data-sentry-source-file="Carousel.tsx">
              {title}
            </Heading>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <If condition={type === "stains"} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
                <Select name="species" options={[{
                value: "All",
                label: "All"
              }, {
                value: "SRO",
                label: "Plain Sliced Red Oak"
              }, {
                value: "SWO",
                label: "Plain Sliced White Oak"
              }, {
                value: "SWM",
                label: "Plain Sliced White Maple"
              }, {
                value: "RWB",
                label: "Rotary White Birch"
              }, {
                value: "SWB",
                label: "Plain Sliced White Birch"
              }, {
                value: "RNB",
                label: "Rotary Natural Birch"
              }, {
                value: "PSC",
                label: "Plain Sliced Cherry"
              }, {
                value: "FCM",
                label: "Flat Cut African Mahogany"
              }, {
                value: "PSW",
                label: "Plain Sliced Walnut"
              }, {
                value: "RRO",
                label: "Rift Red Oak"
              }, {
                value: "RWO",
                label: "Rift White Oak"
              }]} placeholder="Species" value={filters.species || "SRO"} onChange={value => {
                setFilters({
                  ...filters,
                  species: value
                });
              }} data-sentry-element="Select" data-sentry-source-file="Carousel.tsx" />
                <Select name="type" options={[{
                value: "All",
                label: "All"
              }, {
                value: "Standard",
                label: "Standard"
              }, {
                value: "Standard+",
                label: "Standard+"
              }]} placeholder="Type" value={filters.colorType || "All"} onChange={value => {
                setFilters({
                  ...filters,
                  colorType: value
                });
              }} data-sentry-element="Select" data-sentry-source-file="Carousel.tsx" />
              </If>
              <button className="flex items-center gap-2" onClick={() => {
              setGrid(true);
            }}>
                <Grid color={grid ? "black" : "gray"} data-sentry-element="Grid" data-sentry-source-file="Carousel.tsx" />
              </button>
              <button className="flex items-center gap-2" onClick={() => {
              setGrid(false);
            }}>
                <GalleryHorizontal color={!grid ? "black" : "gray"} data-sentry-element="GalleryHorizontal" data-sentry-source-file="Carousel.tsx" />
              </button>
            </div>
          </div>
        </div>
      </If>
      <div className="stack gap-4 overflow-hidden">
        <div ref={emblaRef} className="w-full overflow-visible relative">
          <div className={clsx("embla__container flex w-full md:gap-8 md:ml-8")} ref={contentRef}>
            <For each={results} render={(thumbnail, index) => {
            // console.log(filters.species, thumbnail);
            let image = filters.species !== "All" && type === "stains" ? thumbnail?.images?.find(img => {
              return img?.filename?.includes(filters.species);
            })?.cdn_url : thumbnail?.images?.[0]?.cdn_url;
            if (type === "hpdl") {
              image = thumbnail?.image;
            }
            return <Swatch key={thumbnail?.sys?.id} thumbnail={{
              ...thumbnail,
              image
            }} index={index} currentIndex={0} type={type} image={image} openStory={openStory} {...props} />;
          }} data-sentry-element="For" data-sentry-source-file="Carousel.tsx" />
          </div>
          <If condition={block.carouselType === "Case Studies"} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
            <Parallax className="absolute right-0 top-40 z-0 w-[50vw] max-w-[460px] pointer-events-none opacity-50" speed={0.35} data-sentry-element="Parallax" data-sentry-source-file="Carousel.tsx">
              <svg viewBox="0 0 706 1113" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Carousel.tsx">
                <path d="M0 544L365 271.091L0 271V544Z" fill="#fff" fillOpacity={0.6} data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
                <path d="M365 1113L730 840.091L365 840V1113Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
                <path d="M364 273L729 0.0905172L364 0V273Z" fill="#fff" fillOpacity={0.8} data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
                <path d="M364 836L729 563.091L364 563V836Z" fill="#fff" fillOpacity={0.8} data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
              </svg>
            </Parallax>
          </If>
        </div>

        <div className="flex container mx-auto flex-col lg:flex-row justify-between gap-2 mt-4 items-center px-gutter">
          <div className="flex gap-2">
            <For each={scrollSnaps} render={(thumb, idx) => {
            return <button onClick={() => {
              emblaApi?.scrollTo(idx);
              setCurrentIndex(idx);
            }} className={`border ${currentIndex === idx ? "bg-gold border-gold" : backgroundColor === "Black" ? "bg-white border-white" : "bg-black border-black"} aspect-square w-3 h-3 overflow-hidden`}>
                    <span className="sr-only">{idx}</span>
                  </button>;
          }} data-sentry-element="For" data-sentry-source-file="Carousel.tsx" />
          </div>
          <div className="hidden md:flex gap-2">
            <button className="embla__button embla__button--prev" onClick={prevSlide}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Carousel.tsx">
                <circle cx="16.5" cy="16.5002" r="16.5" transform="rotate(176.915 16.5 16.5002)" fill="#B18626" data-sentry-element="circle" data-sentry-source-file="Carousel.tsx" />
                <path d="M20.4523 24.7993L11.3102 16.9103L19.5515 8.08445" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
              </svg>
            </button>
            <button className="embla__button embla__button--next " onClick={nextSlide}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Carousel.tsx">
                <circle cx="16.5" cy="16.5" r="16.5" fill="#B18626" data-sentry-element="circle" data-sentry-source-file="Carousel.tsx" />
                <path d="M13 8L21.7043 16.3696L13 24.7391" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Carousel.tsx" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <CTAs ctas={ctas} algin="center" data-sentry-element="CTAs" data-sentry-source-file="Carousel.tsx" />
    </section>;
};
interface BaseProps {
  block: any;
  className?: string;
  type?: string;
  image?: string;
}
const Lightbox = ({
  type
}) => {
  const {
    selectedSwatch,
    getSelectedSwatch,
    setSelectedSwatch,
    nextSwatch,
    prevSwatch
  } = useSwatches();
  const swatch = selectedSwatch; //getSelectedSwatch();
  const {
    swatchFilters
  } = useSwatches();
  const {
    species,
    colorType
  } = swatchFilters;
  // console.log("selectedSwatch", selectedSwatch);
  if (!selectedSwatch) {
    return null;
  }

  // console.log("Open Swatch", selectedSwatch);
  let image = species !== "All" && type !== "hpdl" ? swatch?.images?.find(img => {
    return img?.filename?.includes(species);
  })?.cdn_url : swatch?.images?.[0]?.cdn_url;
  if (type === "hpdl") {
    image = swatch?.image?.[0]?.cdn_url;
  }
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedSwatch(null);
      }
      // if (event.key === "ArrowRight" && nextSwatch) {
      //   nextSwatch(event);
      // }
      // if (event.key === "ArrowLeft" && prevSwatch) {
      //   prevSwatch(event);
      // }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.style.overflow = "unset";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [swatch, nextSwatch, prevSwatch, setSelectedSwatch]);
  return <aside className={`fixed top-0 left-0 z-[999999999999999] bg-[rgba(0,0,0,0.5)] grid place-items-center w-full h-full`} onClick={() => {
    setSelectedSwatch(null);
  }} data-sentry-component="Lightbox" data-sentry-source-file="Carousel.tsx">
      <div className="w-[500px] max-w-full bg-white relative">
        <Image src={image} alt={swatch.title} className={`transition-all w-full duration-500 ease-in-out aspect-[1/1] md:aspect-[495/666]`} imgClassName="aspect-[1/1] md:aspect-[495/666] w-full" maxWidth="1000" data-sentry-element="Image" data-sentry-source-file="Carousel.tsx" />
        <div className={`absolute swatch-title pl-2 pr-10 pt-2 top-0 left-0 z-[1] text-[1.25rem] stack`}>
          <span className="leading-none text-base text-black">
            {swatch.title}
          </span>
          <If condition={swatch?.subtitle || swatch?.type} data-sentry-element="If" data-sentry-source-file="Carousel.tsx">
            <span className="text-sm text-gold">
              {swatch?.subtitle || swatch?.type}
            </span>
          </If>
        </div>
      </div>
      <button onClick={() => setSelectedSwatch(null)} className="absolute top-4 right-4">
        <X color="white" size={48} data-sentry-element="X" data-sentry-source-file="Carousel.tsx" />
      </button>

      <button onClick={prevSwatch} className="absolute top-[50%] transform -translate-y-1/2 left-4">
        <ArrowLeft color="white" size={48} data-sentry-element="ArrowLeft" data-sentry-source-file="Carousel.tsx" />
      </button>

      <button onClick={nextSwatch} className="absolute top-[50%] transform -translate-y-1/2 right-4">
        <ArrowRight color="white" size={48} data-sentry-element="ArrowRight" data-sentry-source-file="Carousel.tsx" />
      </button>
    </aside>;
};
const Base = ({
  block,
  className,
  type,
  image,
  openStory,
  ...props
}: BaseProps) => {
  const {
    backgroundColor,
    showTitle,
    title
  } = block;
  const thumbnails = block?.imageCarousel || block?.carouselCollection?.items || [];
  const contentRef = useRef(null);
  const [centerIt, setCenterIt] = useState(false);
  const [grid, setGrid] = useState(true);
  const handleResize = () => {
    if (contentRef.current) {
      const firstChild = contentRef?.current?.firstChild;
      const fullWidth = firstChild?.offsetWidth * thumbnails.length + thumbnails.length * 32 + 32;
      // console.log(title, "resizing");
      // console.log(fullWidth, window.innerWidth);
      if (fullWidth < window.innerWidth) {
        setCenterIt(true);
      } else if (centerIt) {
        setCenterIt(false);
      }
    }
  };
  const ref = useResizeObserver(handleResize);
  if (grid) {
    return <>
        <NoScroll block={block} className={className} contentRef={contentRef} setGrid={setGrid} grid={grid} type={type} image={image} openStory={openStory} {...props} />
        <Lightbox type={type} />
      </>;
  }
  return <>
      <Scrollable block={block} className={className} contentRef={contentRef} setGrid={setGrid} grid={grid} type={type} image={image} openStory={openStory} {...props} data-sentry-element="Scrollable" data-sentry-source-file="Carousel.tsx" />
      <Lightbox type={type} data-sentry-element="Lightbox" data-sentry-source-file="Carousel.tsx" />
    </>;
};

/**
 *
 * Carousel
 *
 */
const Carousel = ({
  block,
  type,
  className,
  canBeGrid,
  image,
  ...props
}: CarouselProps) => {
  const {
    backgroundColor,
    showTitle,
    title
  } = block;
  const thumbnails = block?.imageCarousel || block?.carouselCollection?.items || [];
  const [story, setStory] = useState(null);
  const openStory = story => {
    setStory(story);
  };
  return <>
      <SwatchesProvider swatches={thumbnails} data-sentry-element="SwatchesProvider" data-sentry-source-file="Carousel.tsx">
        <Base block={block} className={className} type={type} image={image} {...props} openStory={openStory} data-sentry-element="Base" data-sentry-source-file="Carousel.tsx" />
      </SwatchesProvider>
    </>;
};
export const Story = ({
  story
}) => {
  return <aside className={`fixed top-0 left-0 z-[999999999999999] bg-[rgba(0,0,0,0.5)] grid place-items-center w-full h-full`} onClick={() => {
    // setSelectedSwatch(null);
  }} data-sentry-component="Story" data-sentry-source-file="Carousel.tsx">
      <div className="bg-white p-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <img src={story?.image?.url} alt={story?.title} />
            </div>
            <div>
              <h2>{story?.title}</h2>
              <p>{story?.description}</p>
              <div dangerouslySetInnerHTML={{
              __html: story?.story?.json
            }} />
            </div>
          </div>
        </div>
      </div>
    </aside>;
};
export default Carousel;